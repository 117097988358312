import { Box, Button, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import axiosInstance from './AxiosAPI';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';

function Setup() {
    const [searchParams] = useSearchParams();
    const [state, setState] = useState('LOADING');
    const [context, setContext] = useState();
    const [entryName, setEntryName] = useState();
    const [code, setCode] = useState();
    const [wppData, setWppData] = useState();

    useEffect(() => {
        (window as any).fbAsyncInit = (): void => {
            (window as any).FB.init({
                appId: '653529706886958',
                cookie: true,
                fbml: false,
                version: 'v20.0',
            });
        };
        (function (d, s, id): void {
            const fjs: any = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            const js: any = d.createElement(s);
            js.id = id;
            js.src = 'https://connect.facebook.net/en_US/sdk.js';
            fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk');
        window.addEventListener('message', sessionInfoListener);
        checkToken();
        return (): void => {
            window.removeEventListener('message', sessionInfoListener);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const token = searchParams.get('token');
        if (token && code && wppData) {
            const { phone_number_id, waba_id } = wppData;
            createEntry(token, code, waba_id, phone_number_id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code, wppData]);

    const createEntry = async (
        token: string,
        code: string,
        waba_id: string,
        phone_number_id: string
    ): Promise<void> => {
        try {
            setState('LOADING');
            const response = await axiosInstance.post(
                '/create?token=' + token,
                {
                    code,
                    waba_id,
                    phone_number_id,
                }
            );
            window.location = response.data;
        } catch (error) {
            console.log(error);
            setState('ERROR');
        }
    };

    const sessionInfoListener = (event: any): void => {
        if (
            event.origin !== 'https://www.facebook.com' &&
            event.origin !== 'https://web.facebook.com'
        ) {
            return;
        }

        try {
            const data = JSON.parse(event.data);
            if (data.type === 'WA_EMBEDDED_SIGNUP') {
                // if user finishes the Embedded Signup flow
                if (data.event === 'FINISH') {
                    setWppData(data.data);
                } else {
                    setState('VALID');
                }
            }
        } catch {}
    };

    async function checkToken(): Promise<void> {
        const token = searchParams.get('token');
        try {
            const response = await axiosInstance.get('/setup?token=' + token);
            setContext(response.data.context);
            setEntryName(response.data.entryName);
            setState('VALID');
        } catch (error) {
            setState('ERROR');
        }
    }

    async function launchWhatsAppSignup(): Promise<void> {
        setState('IN_PROGRESS');
        (window as any).fbq?.('trackCustom', 'WhatsAppOnboardingStart', {
            appId: '653529706886958',
            feature: 'whatsapp_embedded_signup',
        });
        (window as any).FB.login(
            function (response: any) {
                if (response.authResponse) {
                    const code = response.authResponse.code;
                    setCode(code);
                    //Este es el code que toca utilizar
                } else {
                    window.history.back();
                }
            },
            {
                config_id: '417754854438485', // configuration ID goes here
                response_type: 'code', // must be set to 'code' for System User access token
                override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
                extras: {
                    feature: 'whatsapp_embedded_signup',
                    sessionInfoVersion: 2, //  Receive Session Logging Info
                    setup: context,
                },
            }
        );
    }

    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                height: '100vh',
            }}
        >
            <img
                alt={'Capta'}
                style={{
                    width: 400,
                    height: 'auto',
                    marginTop: '10%',
                    maxWidth: '80%',
                }}
                src={'/Capta.svg'}
            />
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    flexGrow: 1,
                }}
            >
                {state === 'LOADING' && (
                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress size={60} />
                    </Box>
                )}
                {(state === 'VALID' || state === 'IN_PROGRESS') && (
                    <React.Fragment>
                        <label
                            style={{
                                fontSize: '20px',
                                marginBottom: 50,
                                fontWeight: 600,
                                padding: '0 20px',
                                textAlign: 'center',
                            }}
                        >
                            {entryName}
                        </label>
                        <Button
                            variant="contained"
                            onClick={launchWhatsAppSignup}
                            sx={{
                                fontSize: '18px',
                                borderRadius: '10px',
                            }}
                            disabled={state === 'IN_PROGRESS'}
                        >
                            Vincular con Facebook
                        </Button>
                    </React.Fragment>
                )}
                {state === 'ERROR' && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <ReportProblemRoundedIcon
                            sx={{
                                fontSize: 120,
                                color: '#cc2936',
                                marginBottom: '10px',
                            }}
                        />
                        <label
                            style={{
                                fontSize: '20px',
                                marginBottom: 20,
                                fontWeight: 600,
                                padding: '0 20px',
                                textAlign: 'center',
                            }}
                        >
                            Ha ocurrido un error, por favor intente más tarde
                        </label>
                        <Button
                            variant="contained"
                            onClick={() => window.history.back()}
                        >
                            Regresar
                        </Button>
                    </Box>
                )}
            </div>
            <div
                style={{
                    marginTop: '10%',
                }}
            ></div>
        </div>
    );
}

export default Setup;
