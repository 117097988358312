import { Route, Routes } from 'react-router-dom';
import Setup from './Setup';
import { ThemeProvider, createTheme } from '@mui/material';

const theme = createTheme({
    palette: {
        primary: {
            main: '#3d5a7f',
        },
        error: {
            main: '#cc2936',
        },
    },
    typography: {
        button: {
            textTransform: 'none',
        },
    },
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Routes>
                <Route path="/setup" element={<Setup />} />
                <Route path="/" element={<div></div>} />
            </Routes>
        </ThemeProvider>
    );
}

export default App;
