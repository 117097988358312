import axios from 'axios';

let headers = {};

const axiosInstance = axios.create({
    baseURL: '/api',
    timeout: 30000,
    headers,
});

export default axiosInstance;
